<div fxLayout="row" fxLayoutGap="10px">
    <div id="container">
        <div data-testid="root" class="root uploaded">
            <goa-icon class="fileicon" type="documents"></goa-icon>
            <div fxFlex="66" class="details">
                <div class="filename">{{ file?.fileName }}</div>
                <div class="filesize">{{ file?.size | fileSize }}</div>
                <div class="timestamp">{{ file?.created | date : 'MMMM d, y' }}</div>
            </div>

            <div *ngIf="allowDownload" class="actions" data-testid="actions">
                <goa-button (click)="onDownloadFile($event)">Download</goa-button>
            </div>
        </div>
    </div>
</div>
