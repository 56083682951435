import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FileData } from '@grants/management/models';

@Component({
    selector: 'shared-file-summary',
    templateUrl: './file-summary.component.html',
    styleUrls: ['./file-summary.component.scss'],
})
export class FileSummaryComponent {
    @Input() file: FileData | undefined = undefined;

    @Input() allowPreview = true;
    @Input() allowDelete = true;
    @Input() allowDownload = false;
    @Input() allowDownloadIfNoPreview = true;

    @Output() readonly deleteFile = new EventEmitter<FileData>();
    @Output() readonly previewFile = new EventEmitter<FileData>();
    @Output() readonly downloadFile = new EventEmitter<FileData>();

    getFileName(): string {
        if (this.file && this.file.fileName && this.file.fileName.length > 0) {
            return this.file.fileName;
        }
        return '';
    }

    onPreviewFile(): void {
        this.previewFile.emit(this.file);
    }

    onDeleteFile(event: MouseEvent): void {
        // avoids focusing the input after clicking on the button
        event.stopPropagation();

        if (this.file) {
            this.deleteFile.emit(this.file);
        }
    }

    onDownloadFile(event: MouseEvent): void {
        // avoids focusing the input after clicking on the button
        event.stopPropagation();

        if (this.file) {
            if (this.file.recordId && this.file.id && this.file.id !== '00000000-0000-0000-0000-000000000000') {
                this.downloadFile.emit(this.file);
            } else {
                this.previewFile.emit(this.file);
            }
        }
    }
}
