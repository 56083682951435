// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
import { Environment } from '@grants/shared/models';

export const environment: Environment = {
    production: false,
    debug: false,
    label: '###BRANCH_LABEL###',
    version: '###APP_VERSION###',
    commit_sha: '###COMMIT_SHA###',
    signUpUrl: 'https://uat.account.alberta.ca/signup',

    access: {
        url: 'https://access-uat.alberta.ca',
        realm: '371bace5-bab7-4522-8a6a-908dc1405302',
        client_id: 'goa:grant-intake-ui',
        adspFormSvcUrl: 'https://form-service.adsp-uat.alberta.ca',
    },
    abGrantApi: {
        host: 'https://dgs.api.uat.alberta.ca/api',
        security_client_id: 'goa:abgrant-user-roles-client',
        endpoints: {
            tenantNameByRealm: '/api/tenant/v1/realm',
        },
    },
};
