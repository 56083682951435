import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPhone' })
export class FormatPhoneNumberPipe implements PipeTransform {
    transform(value: string | number | null | undefined): string {

        const result = value?.toString()?.trim()?.replace(/\D/g, '');

        if (result) {
            // 555 1234
            if (result.length === 7) {
                return `${result.substr(0, 3)}-${result.substr(3)}`;
            }

            // 780 555 1234
            if (result.length === 10) {
                return `(${result.substr(0, 3)}) ${result.substr(3, 3)}-${result.substr(6)}`;
            }

            // 1 780 555 1234
            if (result.length === 11) {
                return `+1 (${result.substr(1, 3)}) ${result.substr(4, 3)}-${result.substr(7)}`;
            }
        }

        return value?.toString() || '';
    }
}