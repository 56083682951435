import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AngularComponentsModule } from '@abgov/angular-components';

import { AccessDeniedComponent, PageNotFoundComponent, FileSummaryComponent } from './components';
import { AnyPipe, FileSizePipe, FormatPhoneNumberPipe, FormatPostalCodePipe, InvalidControlPipe, IsEmptyPipe, PrettyJSONPipe } from './pipes';
import { FormFieldDirective } from './directives/form-field.directive';

const DECLARATIONS = [
    PageNotFoundComponent,
    AccessDeniedComponent,
    FileSummaryComponent,

    FormFieldDirective,

    InvalidControlPipe,
    FileSizePipe,
    AnyPipe,
    IsEmptyPipe,
    PrettyJSONPipe,
    FormatPhoneNumberPipe,
    FormatPostalCodePipe
];

@NgModule({
    imports: [CommonModule, AngularComponentsModule],

    declarations: [...DECLARATIONS],

    exports: [...DECLARATIONS],

    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
