import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPostalCode' })
export class FormatPostalCodePipe implements PipeTransform {
    transform(value: string | null | undefined): string {

        const result = value?.trim()?.replace(' ', '')?.toUpperCase();

        if (result) {
            // 1a1 a1a
            if (result.length === 6) {
                return `${result.substr(0, 3)} ${result.substr(3)}`;
            }
        }

        return value || '';
    }
}